import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  isYouTubeVideoPlaying,
  loadYouTubeVideo,
  pauseYouTubeVideo,
  playYouTubeVideo,
  seekYouTubeVideo
} from '../../services/youtube';
import { sendAnalyticsEvent } from '../../services/analytics';
import { EditToggle } from '../admin/EditToggle';
import { EditableText } from '../admin/EditableText';
import { saveContent, updatePurposeVideo } from '../../services/content';
import { ProgressOverlay } from '../admin/ProgressOverlay';
import { isAuthenticated } from '../../services/auth';

const YouTubeVideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const VideoEditControlsContainer = styled.div`
  position: absolute;
  top: -2rem;
  right: 0;
  text-align: right;
  z-index: 5;

  & button {
    font-size: large;
  }
`;

const VideoIdContainer = styled.div`
  display: ${({ editing }) => (editing ? 'inline-block' : 'none')};
  margin-right: 1rem;
`;

const PlayerContainer = styled.div`
  z-index: 4;
  max-width: 75rem;
`;

const Label = styled.label`
  margin-right: 2rem;
  white-space: nowrap;
`;

const dataBeforeEditing = {};

const videoIdTransformPatterns = [
  {
    pattern: /^https?:\/\/(?:www\.)?youtube\.com\/watch\?(?:.*&)?v=(.+)(?:&.*)?$/,
    replacement: '$1'
  },
  {
    pattern: /^https?:\/\/youtu\.be\/(.+)$/,
    replacement: '$1'
  }
];

export const YouTubeVideo = ({ id, data, x }) => {
  const [videoId, setVideoId] = useState(data.videoId);
  const [editing, setEditing] = useState(false);
  const [busy, setBusy] = useState(false);
  const { region } = useParams();

  const setNormalisedVideoIdOrUrl = input =>
    setVideoId(
      videoIdTransformPatterns.reduce(
        (result, { pattern, replacement }) => (pattern.test(result) ? result.replace(pattern, replacement) : result),
        input
      )
    );

  const edit = () => {
    setEditing(true);
    Object.assign(dataBeforeEditing, data);
  };

  const save = async () => {
    if (isAuthenticated()) {
      setBusy(true);
      updatePurposeVideo(videoId);
      await saveContent(region);
      loadYouTubeVideo(id, videoId);
      setEditing(false);
      setBusy(false);
    }
  };

  const cancel = () => {
    setVideoId(dataBeforeEditing.videoId);
    setEditing(false);
  };

  loadYouTubeVideo(id, videoId);

  useEffect(
    () =>
      x.onChange(() => {
        const videoCanPlay = x.get() >= window.innerWidth / 2;
        const isPlaying = isYouTubeVideoPlaying(id);
        if (videoCanPlay && !isPlaying) {
          seekYouTubeVideo(id);
          playYouTubeVideo(id);
          sendAnalyticsEvent({ category: 'Video', action: videoId });
        } else if (!videoCanPlay && isPlaying) {
          pauseYouTubeVideo(id);
        }
      }),
    [x, id, videoId]
  );

  return (
    <YouTubeVideoContainer>
      {isAuthenticated() && (
        <VideoEditControlsContainer>
          {busy && <ProgressOverlay layout="horizontal" />}
          {editing && <Label>YouTube URL or ID:</Label>}
          <VideoIdContainer editing={editing}>
            <EditableText editing={editing} text={videoId} setText={setNormalisedVideoIdOrUrl} />
          </VideoIdContainer>
          <EditToggle editing={editing} edit={edit} save={save} cancel={cancel} />
        </VideoEditControlsContainer>
      )}
      <PlayerContainer id={id} />
    </YouTubeVideoContainer>
  );
};
