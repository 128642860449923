import React from 'react';
import { transform } from 'popmotion';
import { motion, useTransform } from 'framer-motion';
import { rootElementSize } from '../../constants';
import { createLateFade } from '../../services/animation';
import { PurpleTextContainer } from '../text/PurpleTextContainer';

const { pipe, clamp, interpolate } = transform;

export const PurpleText = ({ x, scaleFactor, text }) => {
  const direction = Math.sign(scaleFactor);
  const xDirected = useTransform(x, val => -direction * val);
  const lateFade = createLateFade(window.innerWidth);

  const scaledX = useTransform(
    x,
    pipe(
      val => scaleFactor * rootElementSize + direction * val,
      direction > 0 ? clamp(0, window.innerWidth) : clamp(-window.innerWidth, 0),
      val => `${val}px`
    )
  );

  const opacity = useTransform(xDirected, pipe(lateFade, interpolate([1, 0], [0, 1])));

  const filter = useTransform(
    xDirected,
    pipe(lateFade, interpolate([1, 0], [10, 0]), val => `blur(${val}px)`)
  );

  return (
    <PurpleTextContainer
      style={{
        right: direction > 0 ? '0' : undefined,
        width: direction > 0 ? scaledX : undefined,
        height: `${18}rem`,
        opacity,
        filter
      }}
    >
      <motion.div style={direction > 0 ? undefined : { x: scaledX }}>{text}</motion.div>
    </PurpleTextContainer>
  );
};
