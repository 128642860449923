import React from 'react';
import styled from 'styled-components';
import { ContentBase } from '../animation/ContentBase';
import { Column } from '../Column';
import { Logo } from '../branding/Logo';
import { PurposeHeadline } from '../text/PurposeHeadline';
import { TelstraPurpleIntroductionText } from '../text/TelstraPurpleIntroductionText';
import { YouTubeVideo } from '../ui/YouTubeVideo';
import { PurpleHalfCirclePurpose } from '../branding/PurpleHalfCirclePurpose';
import { PurpleRectanglePurpose } from '../branding/PurpleRectanglePurpose';
import { getPurposeVideo } from '../../services/content';
import { purposeVideoElementId } from '../../constants';

const PurposeVideoContainer = styled.div`
  flex: 1;
  margin: 4rem;
  width: 70%;
`;

export const Purpose = ({ x }) => (
  <ContentBase id="purpose" x={x} xIsNegative>
    <Column>
      <Logo onClick={() => x.set(0)} />
    </Column>
    <Column>
      <PurposeHeadline>We’re a collective of passionate people who give purpose to technology.</PurposeHeadline>
      <TelstraPurpleIntroductionText>
        Telstra Purple is a newly aggregated entity of 8 technology consulting organisations. The core of everything we
        do is deliberately purposeful - we listen, we learn, we solve problems, we design, we share. We&apos;re Telstra
        Purple.
      </TelstraPurpleIntroductionText>
      <PurposeVideoContainer>
        <YouTubeVideo id={purposeVideoElementId} data={{ videoId: getPurposeVideo() }} x={x} />
      </PurposeVideoContainer>
    </Column>
    <Column>
      <PurpleHalfCirclePurpose />
      <PurpleRectanglePurpose />
    </Column>
  </ContentBase>
);
