import React from 'react';
import { ContentBase } from '../animation/ContentBase';
import { Column, ImageColumn } from '../Column';
import { Logo } from '../branding/Logo';
import { PeopleHeadline } from '../text/PeopleHeadline';
import { PurpleQuarterCirclePeople } from '../branding/PurpleQuarterCirclePeople';
import { PurpleRectanglePeople } from '../branding/PurpleRectanglePeople';
import { SectionHeading } from '../text/SectionHeading';
import { Bio } from '../ui/Bio';
import { getBios } from '../../services/content';

export const People = ({ x }) => (
  <ContentBase id="people" x={x}>
    <Column>
      <Logo onClick={() => x.set(0)} src="Telstra_Purple_Logo_RGB_White.png" alt="Telstra Purple Logo" />
    </Column>
    <Column>
      <PeopleHeadline>People giving purpose to technology</PeopleHeadline>
    </Column>
    <Column>
      <PurpleQuarterCirclePeople />
      <PurpleRectanglePeople />
    </Column>
    <ImageColumn>
      <SectionHeading>Meet the Purple Peeps</SectionHeading>
      {getBios().map((bioData, index) => (
        <Bio
          bioData={bioData}
          index={index}
          /* eslint-disable-next-line react/no-array-index-key */
          key={`${bioData.name.replace(/\s/, '_').toLowerCase()}-${index}`}
        />
      ))}
    </ImageColumn>
  </ContentBase>
);
