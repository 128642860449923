import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { AzureAD } from 'react-aad-msal';
import { ThemeProvider } from 'styled-components';
import { telstraTheme } from '../theme';
import { authProvider } from '../services/auth';
import { Presentation } from './Presentation';
import { RegionList } from './RegionList';
import { AuthenticationControl } from './admin/AuthenticationControl';

export const App = () => (
  <AzureAD provider={authProvider}>
    {authProviderProps => (
      <ThemeProvider theme={telstraTheme}>
        <BrowserRouter>
          <Switch>
            <Route path="/:region">
              <Presentation />
            </Route>
            <Route path="/">
              <RegionList />
            </Route>
          </Switch>
        </BrowserRouter>
        <AuthenticationControl {...authProviderProps} />
      </ThemeProvider>
    )}
  </AzureAD>
);
