import { toast } from 'react-toastify';
import { ensureArrayLength } from '../util';
import { authProvider } from './auth';

let content = {
  bios: [],
  videos: {
    purpose: undefined
  },
  covers: {
    purpose: [],
    people: []
  }
};

const biosCount = 6;
const emptyBio = { imageUrl: '', name: '', bio: '' };

const coversCount = 6;
const emptyCover = { imageUrl: '' };

const normaliseContent = async response => {
  const body = await response.json();

  if (response.status >= 300) {
    throw Error(body && body.message ? `${body.message} (status ${response.status})` : response.status);
  }

  ensureArrayLength(body.bios, biosCount, emptyBio);
  ensureArrayLength(body.covers.people, coversCount, emptyCover);
  ensureArrayLength(body.covers.purpose, coversCount, emptyCover);

  return body;
};

// Note this is loading from the *resource* URI
export const loadContent = async region => {
  try {
    const response = await fetch(`${process.env.REACT_APP_RESOURCE_BASE_URI}/${region}/content.json`, {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    });
    content = await normaliseContent(response);
  } catch (e) {
    toast.error(`Fatal error: Could not load content: ${e}`);
  }
};

// Note this is saving to the *API* URI
export const saveContent = async region => {
  try {
    const { accessToken } = await authProvider.getAccessToken();
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URI}/content/${region}`, {
      method: 'PUT',
      body: JSON.stringify(content, null, 2),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    content = await normaliseContent(response);
  } catch (e) {
    toast.error(`Could not publish content: ${e}`);
  }
};

export const getBios = () => content.bios;

export const updateBio = (index, bio) => {
  content.bios[index] = bio;
};

export const getPurposeVideo = () => content.videos.purpose;

export const updatePurposeVideo = videoId => {
  content.videos.purpose = videoId;
};

export const getCoverImages = () => content.covers;

export const updateCoverImages = (side, images) => {
  content.covers[side] = images;
};
