import styled from 'styled-components';
import { circleWidth } from '../constants';

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ImageColumn = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding-top: ${circleWidth + 1}rem;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-left: -${circleWidth - 2}rem;
  max-height: calc(100vh - 18rem);
  max-width: 50%;
`;
