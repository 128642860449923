import React, { createRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { transparentize } from 'polished';
import { useTransform } from 'framer-motion';
import { handleFileUpload } from '../../services/upload';
import { EditableImage } from './EditableImage';
import { normaliseResourceUri } from '../../util';
import { getCoverImages, saveContent, updateCoverImages } from '../../services/content';
import { EditToggle } from './EditToggle';
import { ProgressOverlay } from './ProgressOverlay';
import { SectionHeading } from '../text/SectionHeading';
import { FadingContainer } from '../animation/FadingContainer';

const CoverEditorOuterContainer = styled.div``;

const EditToggleContainer = styled.div`
  position: absolute;
  top: 0.5rem;
  ${({ side }) => (side === 'people' ? 'right' : 'left')}: 0.5rem;
  z-index: 6;
  background-color: ${({ theme }) => transparentize(0.8, theme.secondaryBlack)};
  white-space: nowrap;

  & button {
    font-size: 1.25rem;
  }
`;

const CoverEditor = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.secondaryBlack};
  color: ${({ theme }) => theme.secondaryWhite};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
`;

const CoverEditorInner = styled.div`
  width: 838px;
  margin: 0 auto;
`;

const CoverEditorImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px;
`;

const CoverEditorImageContainer = styled.div`
  margin: 24px;
`;

const CoverEditorImage = styled.img`
  display: inline-block;
  width: 240px;
  height: 240px;
  object-fit: cover;
`;

const minimumCoverImageDimensions = { width: 1280, height: 1327 };

const ContentCoverEditableImage = ({ index, side, text, editing, imageUrl, setImageUrl }) => {
  const imageRef = createRef();
  const { region } = useParams();

  const updateImageUrl = async ([file]) => {
    const uploadResult = await handleFileUpload(file, minimumCoverImageDimensions);
    if (uploadResult && imageRef.current) {
      imageRef.current.src = uploadResult;
      setImageUrl(uploadResult);
    }
  };

  const notifyFileRejected = rejectedFiles =>
    toast.error(`File(s) are the wrong type: "${rejectedFiles.map(file => file.name).join('", "')}"`);

  return (
    <CoverEditorImageContainer>
      <EditableImage
        editing={editing}
        minimumResolution={minimumCoverImageDimensions}
        onFileAccepted={updateImageUrl}
        onFileRejected={notifyFileRejected}
      >
        <CoverEditorImage
          src={normaliseResourceUri(imageUrl, region, side, index)}
          alt={`${text} cover image #${index + 1}`}
          ref={imageRef}
        />
      </EditableImage>
    </CoverEditorImageContainer>
  );
};

const imagesBeforeEditing = { people: [], purpose: [] };

export const EditableCover = ({ side, text, x, images, setImages, updateExternalImage }) => {
  const [editing, setEditing] = useState(false);
  const [busy, setBusy] = useState(false);
  const { region } = useParams();
  const xNeg = useTransform(x, val => -val);

  const edit = () => {
    imagesBeforeEditing[side] = JSON.parse(JSON.stringify(images));
    setEditing(true);
  };

  const save = async () => {
    setBusy(true);
    updateCoverImages(side, images);
    await saveContent(region);

    // The updated image URLs are available via `getCoverImages`, so use that to update the UI
    const newImages = getCoverImages()[side];
    setImages(newImages);
    newImages.map(({ imageUrl }) => imageUrl).forEach(updateExternalImage);

    setEditing(false);
    setBusy(false);
  };

  const cancel = () => {
    setImages(imagesBeforeEditing[side]);
    setEditing(false);
  };

  const setImageUrlAtIndex = index => imageUrl => {
    images.splice(index, 1, { imageUrl });
    setImages(images);
  };

  return (
    <>
      <FadingContainer x={side === 'people' ? x : xNeg}>
        <FadingContainer x={side === 'purpose' ? x : xNeg} vanishOnly>
          <EditToggleContainer side={side}>
            <EditToggle editing={editing} editText={`Edit ${text} Cover`} edit={edit} save={save} cancel={cancel} />
          </EditToggleContainer>
        </FadingContainer>
      </FadingContainer>
      {busy && <ProgressOverlay iconSize="5x" />}
      <CoverEditorOuterContainer>
        {editing && (
          <CoverEditor>
            <CoverEditorInner>
              <SectionHeading style={{ margin: 0 }}>Edit {text} Cover</SectionHeading>
              <p>Manage images on the {text} side of the home page cover slide show.</p>
              <CoverEditorImagesContainer>
                {images.map(({ imageUrl }, index) => (
                  <ContentCoverEditableImage
                    editing={editing}
                    index={index}
                    side={side}
                    text={text}
                    imageUrl={imageUrl}
                    setImageUrl={setImageUrlAtIndex(index)}
                    key={imageUrl}
                  />
                ))}
              </CoverEditorImagesContainer>
            </CoverEditorInner>
          </CoverEditor>
        )}
      </CoverEditorOuterContainer>
    </>
  );
};
