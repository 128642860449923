import React from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { transparentize } from 'polished';
import { stopEventIf } from '../../util';

// This ensures HTML cannot be copied from external sources
const updateWith = (setText, name) => evt => {
  const text = evt.currentTarget.innerText.trim();
  if (text.length === 0) {
    toast.warn(`You have entered an empty ${name}`);
  }
  setText(text);
};

const getBorderColor = (theme, editing, text) => {
  if (editing) {
    return text ? theme.darkGrey : theme.validationRed;
  }
  return 'transparent';
};

const Container = styled.div`
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme, editing, text }) => getBorderColor(theme, editing, text)};
  border-radius: 0.25rem;
  margin: ${({ editing }) => (editing ? '-0.25rem -0.5rem' : 'none')};
  padding: ${({ editing }) => (editing ? '0.25rem 0.5rem' : 'none')};
  width: 100%;
  min-height: 1.4rem;

  &:hover {
    background-color: ${({ theme, contentEditable }) =>
      contentEditable ? transparentize(0.8, theme.lightGrey) : 'transparent'};
  }
`;

export const EditableText = ({ name, text, setText, editing }) => (
  <Container
    role="article"
    contentEditable={editing}
    editing={editing}
    text={text}
    onClick={stopEventIf(editing)}
    onKeyPress={stopEventIf(editing)}
    onBlur={updateWith(setText, name)}
    /* eslint-disable-next-line react/no-danger */
    dangerouslySetInnerHTML={{ __html: text }}
  />
);
