import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faEdit,
  faTimes,
  faTimesCircle,
  faUpload,
  faImage,
  faSignInAlt,
  faSignOutAlt,
  faSpinner
} from '@fortawesome/free-solid-svg-icons';

export const initialiseIcons = () =>
  library.add(faTimes, faTimesCircle, faUpload, faEdit, faImage, faSignInAlt, faSignOutAlt, faSpinner);
