import React from 'react';
import styled from 'styled-components';
import { transparentize } from 'polished';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropzone from 'react-dropzone';

const Container = styled.div`
  display: inline-block;
  position: relative;
  line-height: 1;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme, editing }) => (editing ? theme.darkGrey : 'transparent')};
  border-radius: 4px;
  margin: ${({ editing }) => (editing ? '-0.5rem' : 'none')};
  padding: ${({ editing }) => (editing ? '0.5rem' : 'none')};
`;

const Overlay = styled.div`
  display: ${({ editing }) => (editing ? 'block' : 'none')};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  // TODO Why does this need to be 1px less than the Container's border-radius? (Otherwise there is a gap)
  border-radius: 3px;
  background-color: ${({ theme }) => transparentize(0.8, theme.lightGrey)};
  opacity: 80%;

  &:hover {
    background-color: ${({ theme, editing }) => (editing ? transparentize(0.67, theme.lightGrey) : 'transparent')};
    opacity: initial;
  }
`;

const DropzoneRoot = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Instructions = styled.p`
  font-size: xx-small;
`;

export const EditableImage = ({ children, editing, onFileAccepted, onFileRejected, minimumResolution }) => (
  <Container editing={editing}>
    {children}
    <Overlay editing={editing}>
      <Dropzone onDropAccepted={onFileAccepted} onDropRejected={onFileRejected} accept="image/jpeg">
        {({ getRootProps, getInputProps }) => (
          <DropzoneRoot {...getRootProps()}>
            <input {...getInputProps()} />
            <div>
              <div>
                <FontAwesomeIcon icon="upload" />
              </div>
              <Instructions>Drag-and-drop or click to replace image</Instructions>
              <Instructions>JPEG images only</Instructions>
              {minimumResolution && (
                <Instructions>
                  Minimum resolution {minimumResolution.width} x {minimumResolution.height} pixels
                </Instructions>
              )}
            </div>
          </DropzoneRoot>
        )}
      </Dropzone>
    </Overlay>
  </Container>
);
