import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMotionValue } from 'framer-motion';
import { ContentCover } from './animation/ContentCover';
import { MovingArrowContainer } from './ui/MovingArrowContainer';
import { DragLine } from './ui/DragLine';
import { People } from './content/People';
import { Purpose } from './content/Purpose';
import { ProgressOverlay } from './admin/ProgressOverlay';
import { sendAnalyticsPageView } from '../services/analytics';
import { purposeVideoElementId } from '../constants';
import { isYouTubeVideoPlaying } from '../services/youtube';
import { initialiseInteractionTimeout, interactionOccurred } from '../services/interaction';
import { loadContent } from '../services/content';

export const Presentation = () => {
  const [loading, setLoading] = useState(true);
  const x = useMotionValue(0);
  const { region } = useParams();

  loadContent(region).then(() => setLoading(false));

  sendAnalyticsPageView();

  // Reset the slider if the window is resized
  window.addEventListener('resize', () => x.set(0));

  // Reset the slider after a period of non-interaction, ready for the next viewer
  initialiseInteractionTimeout(
    () => isYouTubeVideoPlaying(purposeVideoElementId),
    () => x.set(0)
  );

  // Moving the slider `x` position is an interaction
  x.onChange(interactionOccurred);

  return loading ? (
    <ProgressOverlay iconSize="5x" text="Loading..." />
  ) : (
    <>
      <ContentCover text="Purpose" side="purpose" x={x} />
      <ContentCover text="People" side="people" x={x} />
      <MovingArrowContainer x={x} />
      <DragLine x={x} />
      <People x={x} />
      <Purpose x={x} />
    </>
  );
};
