import styled from 'styled-components';
import { circleWidth } from '../../constants';

export const PurpleQuarterCirclePeople = styled.div`
  z-index: 2;
  width: ${circleWidth}rem;
  height: ${circleWidth}rem;
  border-radius: 0 0 ${circleWidth}rem 0;
  margin-left: ${circleWidth / 2}rem;
  background-color: ${({ theme }) => theme.primaryBlue};
`;
