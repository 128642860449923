import { LoginType, MsalAuthProvider, AuthenticationState } from 'react-aad-msal';

export const authProvider = new MsalAuthProvider(
  {
    auth: {
      authority: process.env.REACT_APP_MSAL_AUTHORITY || 'https://login.microsoftonline.com/common',
      clientId: process.env.REACT_APP_MSAL_CLIENT_APP_ID,
      postLogoutRedirectUri: window.location.origin,
      redirectUri: window.location.origin,
      validateAuthority: true
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: true
    }
  },
  {
    scopes: [`api://${process.env.REACT_APP_MSAL_CLIENT_APP_ID}/access_as_user`]
  },
  {
    loginType: LoginType.Redirect
  }
);

export const isAuthenticated = () => authProvider.authenticationState === AuthenticationState.Authenticated;
