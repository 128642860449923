import React from 'react';
import styled from 'styled-components';
import { motion, useTransform } from 'framer-motion';
import { transform } from 'popmotion';
import { Arrow, ArrowSeparationLine, ArrowText } from './Arrow';
import { FadingContainer } from '../animation/FadingContainer';

const { pipe, interpolate } = transform;

const ArrowContainer = styled(motion.div)`
  position: absolute;
  z-index: 3;
  top: 70%;
  left: 50%;
`;

const useInterpolatedTransform = (x, minIn, maxIn, minOut, maxOut) =>
  useTransform(
    x,
    pipe(interpolate([minIn, maxIn], [minOut, maxOut]), val => `${val}rem`)
  );

export const MovingArrowContainer = ({ x }) => {
  const halfWidth = Math.floor(window.innerWidth / 2);
  const arrowXVal = useTransform(x, val => `calc(50% + ${val}px)`);
  const rightText = useInterpolatedTransform(x, 0, halfWidth, 6, 3);
  const rightArrow = useInterpolatedTransform(x, 0, halfWidth, 5, 2);
  const leftText = useInterpolatedTransform(x, -halfWidth, 0, 3, 6);
  const leftArrow = useInterpolatedTransform(x, -halfWidth, 0, 2, 5);
  const xNeg = useTransform(x, val => -val);
  const xAbs = useTransform(x, Math.abs);

  return (
    <ArrowContainer style={{ left: arrowXVal }}>
      <FadingContainer x={xNeg}>
        <ArrowText style={{ right: rightText, textAlign: 'end' }}>Drag Left</ArrowText>
        <Arrow style={{ right: rightArrow }} />
      </FadingContainer>
      <FadingContainer x={xAbs}>
        <ArrowSeparationLine style={{ right: '-3rem' }} />
      </FadingContainer>
      <FadingContainer x={x}>
        <ArrowText style={{ left: leftText }}>Drag Right</ArrowText>
        <Arrow style={{ left: leftArrow, transform: 'scaleX(-1)' }} />
      </FadingContainer>
    </ArrowContainer>
  );
};
