import React from 'react';
import styled from 'styled-components';
import { motion, useTransform } from 'framer-motion';
import { createFade, createVanish } from '../../services/animation';

const Fading = styled(motion.div)`
  opacity: ${({ opacity }) => opacity};
  display: ${({ display }) => display};
`;

export const FadingContainer = ({ x, style, children, vanishOnly }) => {
  const opacity = useTransform(x, vanishOnly ? () => 1 : createFade(window.innerWidth));
  const display = useTransform(x, createVanish(window.innerWidth));

  return (
    <Fading style={style} opacity={opacity} display={display}>
      {children}
    </Fading>
  );
};
