import React from 'react';
import ReactDOM from 'react-dom';
import { config } from 'dotenv';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import { App } from './components/App';
import * as serviceWorker from './serviceWorker';
import { initialiseToast } from './services/toast';
import { initialiseGoogleAnalytics } from './services/analytics';
import { initialiseYouTubeAPI } from './services/youtube';
import { initialiseIcons } from './services/icons';

config();

initialiseToast();
initialiseGoogleAnalytics();
initialiseIcons();
initialiseYouTubeAPI();

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change unregister() to register() below.
// Note this comes with some pitfalls.  Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
