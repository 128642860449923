import React from 'react';
import styled from 'styled-components';
import { motion, useTransform } from 'framer-motion';
import { createLateFade } from '../../services/animation';

const ContentContainer = styled(motion.div)`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  max-height: 100vh;

  color: ${({ theme }) => theme.secondaryWhite};
  background-color: ${({ theme }) => theme.secondaryBlack};

  justify-content: flex-start;
  align-items: flex-start;

  position: absolute;
`;

export const ContentBase = ({ x, xIsNegative, children }) => {
  const lateFade = createLateFade(window.innerWidth);
  const opacity = useTransform(x, val => lateFade(xIsNegative ? -val : val));
  return <ContentContainer style={{ opacity }}>{children}</ContentContainer>;
};
