import styled from 'styled-components';
import { motion } from 'framer-motion';

export const PurpleTextContainer = styled(motion.div)`
  font-size: 15rem;
  margin: 0;
  padding: 0;
  z-index: 2;

  width: 100%;
  overflow: hidden;

  color: ${({ theme }) => theme.secondaryWhite};

  position: absolute;

  top: calc(50% - 15rem);
`;
