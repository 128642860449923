import { toast } from 'react-toastify';
import { checkImageDimensions } from '../util';

export const handleFileUpload = async (file, minimumImageDimensions) => {
  try {
    const uploadResult = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onabort = () => reject(Error(`The reading operation for "${file.name}" was aborted, please try again`));
      reader.onerror = () => reject(Error(`An error occurred reading "${file.name}", please try again`));
      reader.onload = async () => resolve(reader.result);
      reader.readAsDataURL(file);
    });

    const { result, expected, actual } = await checkImageDimensions(uploadResult, minimumImageDimensions);

    switch (result) {
      case 'too-small':
        toast.error(`The image "${file.name}" is too low resolution: minimum ${expected}, received ${actual}`);
        return undefined;

      case 'incorrect-ratio':
        toast.warn(`The image "${file.name}" has an incorrect aspect ratio: expected ${expected}, received ${actual}`);
        return uploadResult;

      case 'success':
        toast.success(`The image "${file.name}" was successfully uploaded`);
        return uploadResult;

      default:
        return undefined;
    }
  } catch (e) {
    toast.error(e);
    return undefined;
  }
};
