// YouTube API reference is here: https://developers.google.com/youtube/iframe_api_reference

// Map of { id: { videoId, player } }; id is the DOM ID where the player is loaded; videoId is the video ID; player is the `YT.Player` object
const players = {};

// Map of { id: videoId }; id is the DOM ID to load the video into; videoId is the video to load
const pendingPlayers = {};

export const loadYouTubeVideo = (id, videoId) => {
  if (!window.YT || !window.YT.Player) {
    // The API isn't loaded yet, so queue the player for creation later
    pendingPlayers[id] = videoId;
    return;
  }

  // Check whether a video is already loaded in this element
  if (players[id]) {
    // If the video loaded is the same as the video being loaded, then we're done
    if (players[id].videoId === videoId) {
      return;
    }

    // The YT API doesn't give a `restore()` or similar function, so we have to clean up ourselves
    // The new `div` is created to be the container for the next video
    const container = document.getElementById(id);
    if (container) {
      const replacement = document.createElement('div');
      replacement.id = id;
      container.parentElement.insertBefore(replacement, container);
      container.remove();
    }
  }

  // The YouTube API is unreliable, it seems; I tried several different (better) workarounds but this `setTimeout` was
  // the only thing that would reliably get the video to load on page load
  setTimeout(() => {
    players[id] = {
      videoId,
      player: new window.YT.Player(id, {
        videoId,
        width: '100%',
        height: '100%',
        playerVars: {
          controls: 0,
          // Does not disable related videos, but sticks to the same channel.
          // https://developers.google.com/youtube/player_parameters.html?playerVersion=HTML5#rel
          rel: 0
        }
      })
    };
  }, 0);
};

// This function should be called once only
export const initialiseYouTubeAPI = () => {
  const tag = document.createElement('script');
  tag.src = 'https://www.youtube.com/iframe_api';
  const firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  window.onYouTubeIframeAPIReady = () => {
    // If any videos attempted to load before the YT API is ready, load them again now
    Object.keys(pendingPlayers).forEach(id => {
      loadYouTubeVideo(id, pendingPlayers[id]);
      delete pendingPlayers[id];
    });
  };
};

export const isYouTubeVideoPlaying = id =>
  players[id] && typeof players[id].player.getPlayerState === 'function' && players[id].player.getPlayerState() === 1;

export const playYouTubeVideo = id =>
  players[id] && typeof players[id].player.playVideo === 'function' && players[id].player.playVideo();

export const pauseYouTubeVideo = id =>
  players[id] && typeof players[id].player.pauseVideo === 'function' && players[id].player.pauseVideo();

export const seekYouTubeVideo = (id, time = 0) =>
  players[id] && typeof players[id].player.seekTo === 'function' && players[id].player.seekTo(time);
