import React, { createRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { motion, useTransform } from 'framer-motion';
import { EditableCover } from '../admin/EditableCover';
import { crossFadeTimeMs, timePerImageMs } from '../../constants';
import { PurpleText } from './PurpleText';
import { getCoverImages } from '../../services/content';
import { normaliseResourceUri } from '../../util';
import { isAuthenticated } from '../../services/auth';

const ImageFlicker = styled(motion.div)`
  display: block;
  position: relative;
  background-color: ${({ color, theme }) => (color === 'people' ? theme.primaryRed : theme.primaryBlue)};
  width: ${({ width }) => width};
  height: 100%;
  max-height: 100vh;
`;

const framePoint = (value, totalPicturesCount) => `${(value / (totalPicturesCount * timePerImageMs)) * 100}%`;

const createFadeOutIn = ({ totalPicturesCount }) => keyframes`
  0%, 100% {
    opacity: 0;
  }
  ${framePoint(crossFadeTimeMs, totalPicturesCount)} {
    opacity: 1;
  }
  ${framePoint(timePerImageMs + crossFadeTimeMs, totalPicturesCount)} {
    opacity: 1;
  }
  ${framePoint(timePerImageMs + crossFadeTimeMs * 2, totalPicturesCount)} {
    opacity: 0;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100vh;
  position: absolute;
  left: 0;
  background-image: url(${({ imageUrl }) => imageUrl});
  background-repeat: no-repeat;
  background-position-x: 0;
  background-position-y: 20%;
  background-size: cover;
  transform: ${({ isFace }) => (isFace ? 'scaleX(-1)' : null)};
  opacity: 0;
  animation: ${({ totalPicturesCount }) => timePerImageMs * totalPicturesCount}ms ease-in-out
    ${({ animationDelay }) => animationDelay}ms infinite ${({ animationName }) => animationName};
`;

// Generate `animation-delay` values such that the image cycle is dictated by clock time, not page load time
// This means that when an image is updated, it will slot into the correct part of the animation cycle
const getAnimationDelayMs = (side, index, allImages) => {
  const cyclePeriod = timePerImageMs * (allImages.people.length + allImages.purpose.length);
  const offset = side === 'people' ? 0 : allImages.people.length;
  return (index + offset) * timePerImageMs - (Date.now() % cyclePeriod);
};

export const ContentCover = ({ x, side, text }) => {
  const { region } = useParams();
  const renderTimestamp = Date.now();
  const allImages = getCoverImages();
  const totalPicturesCount = allImages.people.length + allImages.purpose.length;
  const [images, setImages] = useState(allImages[side]);
  const textScaleFactor = side === 'people' ? -28.375 : 24.75;
  const direction = Math.sign(textScaleFactor);
  const width = useTransform(x, val => `${window.innerWidth / 2 + val * direction}px`);

  const imageContainerRefs = images.map(() => createRef());
  const updateExternalImage = (imageUrl, index) => {
    if (imageContainerRefs[index] && imageContainerRefs[index].current) {
      const normalisedUri = normaliseResourceUri(imageUrl, region, side, index);
      imageContainerRefs[index].current.style.backgroundImage = `url(${normalisedUri})`;
    }
  };

  // Note that each `ImageContainer` receives a `key` that includes the current timestamp, which forces React to
  // render all the images whenever the parent `ContentCover` is rendered, which keeps the animations in sync
  return (
    <>
      <ImageFlicker width={width} color={side}>
        {images.map(({ imageUrl }, index) => {
          const normalisedImageUrl = normaliseResourceUri(imageUrl, region, side, index);
          return (
            <ImageContainer
              data-animated-image
              imageUrl={normalisedImageUrl}
              isFace={direction < 0}
              totalPicturesCount={totalPicturesCount}
              animationName={createFadeOutIn({ totalPicturesCount })}
              animationDelay={getAnimationDelayMs(side, index, allImages, renderTimestamp)}
              ref={imageContainerRefs[index]}
              key={`${normalisedImageUrl}`}
            />
          );
        })}
        <PurpleText x={x} scaleFactor={textScaleFactor} text={text} />
      </ImageFlicker>
      {isAuthenticated() && (
        <EditableCover
          side={side}
          text={text}
          x={x}
          images={images}
          setImages={setImages}
          updateExternalImage={updateExternalImage}
        />
      )}
    </>
  );
};
