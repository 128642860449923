import { transform } from 'popmotion';
import { triggerDistanceRatio, lateFadeTriggerDistanceRatio } from '../constants';

const { pipe, clamp, interpolate, nonlinearSpring, applyOffset } = transform;

export const createFade = windowWidth =>
  pipe(clamp(0, windowWidth * triggerDistanceRatio), nonlinearSpring(0.65, 0), interpolate([0, 6], [1, 0]));

export const createLateFade = windowWidth =>
  pipe(
    applyOffset(0, windowWidth * lateFadeTriggerDistanceRatio),
    clamp(0, windowWidth * lateFadeTriggerDistanceRatio),
    createFade(windowWidth)
  );

export const createVanish = windowWidth => val =>
  Math.abs(val) > Math.abs(triggerDistanceRatio * windowWidth) &&
  Math.sign(val) === Math.sign(triggerDistanceRatio * windowWidth)
    ? 'none'
    : 'block';
