import React from 'react';
import styled from 'styled-components';
import { motion, useTransform } from 'framer-motion';
import { transform } from 'popmotion';
import { draggerWidth } from '../../constants';
import { sendAnalyticsEvent } from '../../services/analytics';
import { isGoogleAnalyticsEnabled } from '../../services/flags';

const { pipe, interpolate } = transform;

const Dragger = styled(motion.div)`
  position: absolute;
  width: ${draggerWidth}rem;
  height: 100%;
  left: calc(50% - ${draggerWidth / 2}rem);
  z-index: 3;
  background-color: transparent;
  cursor: ew-resize;
`;

export const DragLine = ({ x }) => {
  const halfWidth = window.innerWidth / 2;

  if (isGoogleAnalyticsEnabled()) {
    x.onChange(() => {
      if (Math.abs(x.get()) === halfWidth) {
        const action = x.current > 0 ? 'purpose' : 'people';
        sendAnalyticsEvent({ category: 'Expansion', action });
      }
    });
  }

  const width = useTransform(
    x,
    pipe(interpolate([0, halfWidth], [draggerWidth, 20]), val => `${val}rem`)
  );

  const left = useTransform(
    x,
    pipe(interpolate([0, halfWidth], [draggerWidth / 2, 20]), val => `calc(50% - ${val}rem)`)
  );

  return (
    <Dragger
      drag="x"
      style={{ x, width, left }}
      dragElastic={0}
      dragMomentum
      dragConstraints={{ left: -halfWidth, right: halfWidth }}
    />
  );
};
