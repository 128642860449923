import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { PurpleHalfCirclePurpose } from './branding/PurpleHalfCirclePurpose';
import { PurpleRectanglePurpose } from './branding/PurpleRectanglePurpose';
import { Column } from './Column';
import { Logo } from './branding/Logo';

// Hardcoded list, it's only for this component
const regions = [
  {
    path: '/new-south-wales',
    label: 'New South Wales'
  },
  {
    path: '/queensland',
    label: 'Queensland'
  },
  {
    path: '/singapore',
    label: 'Singapore'
  },
  {
    path: '/united-kingdom',
    label: 'United Kingdom'
  },
  {
    path: '/victoria',
    label: 'Victoria'
  },
  {
    path: '/western-australia',
    label: 'Western Australia'
  }
];

const Container = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.secondaryWhite};
`;

const Heading = styled.h1`
  font-size: 2rem;
  font-weight: normal;
`;

const Inner = styled.div`
  padding: 0.5rem 1.5rem;
  border: 2px solid ${({ theme }) => theme.secondaryWhite};
  border-radius: 8px;
`;

const InnerContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
`;

const Paragraph = styled.p`
  color: ${({ theme }) => theme.secondaryWhite};
`;

const List = styled.ul`
  text-align: center;
  list-style: none;
  padding: 0;
`;

const RegionLink = styled(Link)`
  color: ${({ theme }) => theme.secondaryWhite};
  font-size: x-large;
`;

export const RegionList = () => (
  <Container>
    <Column>
      <Logo />
    </Column>
    <Column style={{ width: '75%' }}>
      <InnerContainer>
        <Heading>Meet Telstra Purple</Heading>
        <Inner>
          <Paragraph>Please select a region to display the relevant presentation:</Paragraph>
          <List>
            {regions.map(({ path, label }) => (
              <li key={path}>
                <RegionLink to={path}>{label}</RegionLink>
              </li>
            ))}
          </List>
        </Inner>
      </InnerContainer>
    </Column>
    <Column>
      <PurpleHalfCirclePurpose />
      <PurpleRectanglePurpose />
    </Column>
  </Container>
);
