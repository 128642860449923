import styled from 'styled-components';

export const Logo = styled.div`
  width: 8rem;
  height: 8rem;

  margin: 4rem 4rem 0 4rem;
  z-index: 5;
  cursor: pointer;

  background-image: url('Telstra_Purple_Logo_RGB_White.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-color: ${({ theme }) => theme.secondaryBlack};
`;
