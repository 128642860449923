import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const ArrowHead = styled(motion.div)`
  border-bottom: white solid 2px;
  border-left: white solid 2px;
  transform: rotate(45deg);
  width: 1rem;
  height: 1rem;
  position: absolute;
`;

const ArrowLine = styled(motion.div)`
  border-bottom: white solid 2px;
  width: 2rem;
  height: 0.5rem;
  position: absolute;
  left: -2px;
`;

const ArrowContainer = styled(motion.div)`
  position: absolute;
  z-index: 2;
  top: 80%;
`;

export const ArrowText = styled(motion.div)`
  font-weight: lighter;
  font-size: 1.5rem;
  color: ${props => props.theme.secondaryWhite};
  position: absolute;
  top: calc(80% - 0.75rem);
  width: 8rem;
`;

export const ArrowSeparationLine = styled(motion.div)`
  border-bottom: white dashed 2px;
  z-index: 2;
  width: 6rem;
  height: 0.5rem;
  position: absolute;
  top: 80%;
`;

export const Arrow = React.forwardRef((props, ref) => (
  <ArrowContainer ref={ref} style={{ position: 'absolute' }} {...props}>
    <ArrowHead />
    <ArrowLine />
  </ArrowContainer>
));
