import GoogleAnalytics from 'react-ga';
import { isGoogleAnalyticsEnabled } from './flags';

export const initialiseGoogleAnalytics = () => {
  if (isGoogleAnalyticsEnabled()) {
    GoogleAnalytics.initialize(process.env.REACT_APP_GA_TRACKING_ID);
  }
};

export const sendAnalyticsPageView = () => {
  if (isGoogleAnalyticsEnabled()) {
    GoogleAnalytics.pageview(window.location.pathname + window.location.search);
  }
};

export const sendAnalyticsEvent = event => {
  if (isGoogleAnalyticsEnabled()) {
    GoogleAnalytics.event(event);
  }
};
