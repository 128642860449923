import React from 'react';
import styled from 'styled-components';
import { AuthenticationState } from 'react-aad-msal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { isAuthenticationEnabled } from '../../services/flags';
import { Spin } from '../animation/Spin';

const Container = styled.div`
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  z-index: 6;
`;

const Button = styled.button`
  color: ${({ theme }) => theme.secondaryWhite};
  background: rgba(0, 0, 0, 0.25);
  padding: 2px 4px;
  border: none;
  cursor: pointer;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 4px;
`;

export const AuthenticationControl = ({ login, logout, authenticationState, accountInfo, error }) => {
  if (!isAuthenticationEnabled()) {
    return null;
  }

  if (error) {
    toast.error(`Authentication error: ${error.errorMessage}`);
  }

  const isInProgress = authenticationState === AuthenticationState.InProgress;

  return (
    <Container>
      {authenticationState === AuthenticationState.Authenticated ? (
        <Button type="button" onClick={logout}>
          <Icon icon="sign-out-alt" />
          Sign out ({accountInfo.account.name})
        </Button>
      ) : (
        <Button type="button" onClick={login} disabled={isInProgress}>
          {isInProgress ? (
            <Spin>
              <Icon icon="spinner" />
            </Spin>
          ) : (
            <Icon icon="sign-in-alt" />
          )}
          {isInProgress ? 'Signing in' : 'Sign in'}
        </Button>
      )}
    </Container>
  );
};
