export const stopEventAnd = handler => async evt => {
  evt.stopPropagation();
  if (handler) {
    await handler(evt);
  }
};

export const stopEventIf = condition => evt => {
  if (condition) {
    evt.stopPropagation();
  }
};

// Resolves to an array: { result, expected, actual }  (If the `result` is `success`, it is the only key).
// The `result` is either `success`, `too-small` or `incorrect-ratio`.  The other keys are for displaying errors.
export const checkImageDimensions = async (imageSource, minimumDimensions) =>
  new Promise(resolve => {
    const tempImage = new Image();
    tempImage.src = imageSource;
    tempImage.onload = () => {
      if (tempImage.width < minimumDimensions.width || tempImage.height < minimumDimensions.height) {
        return resolve({
          result: 'too-small',
          expected: `${minimumDimensions.width}x${minimumDimensions.height}`,
          actual: `${tempImage.width}x${tempImage.height}`
        });
      }

      if (tempImage.width / tempImage.height !== minimumDimensions.width / minimumDimensions.height) {
        return resolve({
          result: 'incorrect-ratio',
          expected: `${String(minimumDimensions.width / minimumDimensions.height).substring(0, 4)}:1`,
          actual: `${String(tempImage.width / tempImage.height).substring(0, 4)}:1`
        });
      }

      return resolve({ result: 'success' });
    };
  });

export const normaliseResourceUri = (uri, region, type, index) => {
  if (!uri) {
    // The placeholders for the Purpose cover are indexed
    const placeholder = type !== 'bio' ? `${type}-${index}` : type;
    return `placeholders/${placeholder}.jpg`;
  }
  if (/^data:/.test(uri)) {
    return uri;
  }
  return `${process.env.REACT_APP_RESOURCE_BASE_URI}/${region}/${uri}`;
};

export const ensureArrayLength = (array, length, item) => {
  while (array.length < length) {
    array.push({ ...item });
  }
};
