import styled from 'styled-components';
import { circleWidth } from '../../constants';

export const PurpleHalfCirclePurpose = styled.div`
  z-index: 2;
  background-color: ${({ theme }) => theme.primaryBlue};
  width: ${circleWidth}rem;
  height: ${circleWidth * 2}rem;
  border-bottom-right-radius: ${circleWidth * 2}rem;
  border-top-right-radius: ${circleWidth * 2}rem;
  margin-left: ${circleWidth / 2}rem;
`;
