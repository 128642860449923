import { restartIntervalDurationMs, restartTimeoutMs } from '../constants';

let lastInteraction;

const checkRestart = (isRestartBlocked, restart) => () => {
  if (lastInteraction < Date.now() - restartTimeoutMs && !isRestartBlocked()) {
    restart();
  }
};

export const initialiseInteractionTimeout = (isRestartBlocked, restart) => {
  lastInteraction = Date.now();
  setInterval(checkRestart(isRestartBlocked, restart), restartIntervalDurationMs);
};

export const interactionOccurred = () => {
  lastInteraction = Date.now();
};
