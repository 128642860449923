import React, { createRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { sendAnalyticsEvent } from '../../services/analytics';
import { EditableText } from '../admin/EditableText';
import { EditableImage } from '../admin/EditableImage';
import { EditToggle } from '../admin/EditToggle';
import { saveContent, updateBio } from '../../services/content';
import { ProgressOverlay } from '../admin/ProgressOverlay';
import { handleFileUpload } from '../../services/upload';
import { normaliseResourceUri } from '../../util';
import { interactionOccurred } from '../../services/interaction';
import { isAuthenticated } from '../../services/auth';

const BioBox = styled(motion.div)`
  cursor: pointer;
  padding: 0 1.5rem 1.5rem 1.5rem;
  width: ${({ isZoomed }) => (isZoomed ? '32rem' : 'auto')};
  display: flex;
  background-color: ${({ theme }) => theme.secondaryBlack};
`;

const BioBoxInner = styled.div`
  position: relative;
  width: 100%;
`;

const BioBoxIcon = styled(motion.div)`
  cursor: pointer;
  position: absolute;
  top: -2px;
  right: 4px;
  font-size: x-large;
  line-height: 1;

  &:before {
    content: '\u25f3';
    color: ${({ theme }) => theme.lightGrey};
    display: ${({ isZoomed }) => (isZoomed ? 'none' : 'block')};
  }

  &:after {
    content: '\u00D7';
    color: ${({ theme }) => theme.darkGrey};
    display: ${({ isZoomed }) => (isZoomed ? 'block' : 'none')};
  }
`;

const BioImageContainer = styled.div`
  width: 200px;
  height: 150px;
  overflow: hidden;
  text-align: center;
`;

const BioImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  &:hover {
    outline: ${({ theme, isZoomed }) => (isZoomed ? undefined : `solid 1px ${theme.lightGrey}`)};
  }
`;

const Text = styled.article`
  display: ${({ isZoomed }) => (isZoomed ? 'block' : 'none')};
  font-size: xx-small;
  padding: 0 0;
`;

const Frame = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme }) => theme.secondaryBlack};
  z-index: 4;
  opacity: 0.95;
  cursor: pointer;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const EditToggleContainer = styled.div`
  position: absolute;
  display: inline-block;
  top: -1px;
  right: ${({ editing }) => (editing ? '0' : '32px')};

  & button {
    font-size: x-small;
  }
`;

const minimumBioImageDimensions = { width: 400, height: 300 };

const dataBeforeEditing = {};

export const Bio = ({ index, bioData }) => {
  const [isZoomed, setIsZoomed] = useState(false);
  const [editing, setEditing] = useState(false);
  const [busy, setBusy] = useState(false);
  const [data, setData] = useState(bioData);
  const { region } = useParams();

  const animate = isZoomed
    ? {
        position: 'absolute',
        left: '40%',
        top: '30%',
        scale: 2,
        zIndex: 4,
        flexDirection: 'row'
      }
    : {
        position: 'static',
        scale: 1,
        zIndex: 3,
        flexDirection: 'column'
      };

  const toggleZoom = () => {
    if (!isZoomed) {
      sendAnalyticsEvent({ category: 'Bio', action: data.name });
      setIsZoomed(true);
    } else if (!editing) {
      setIsZoomed(false);
    }
    interactionOccurred();
  };

  const edit = () => {
    if (isAuthenticated()) {
      Object.assign(dataBeforeEditing, data);
      setEditing(true);
    }
  };

  const save = async () => {
    if (isAuthenticated()) {
      setBusy(true);
      updateBio(index, data);
      await saveContent(region);
      setEditing(false);
      setBusy(false);
    }
  };

  const cancel = () => {
    setData(dataBeforeEditing);
    setEditing(false);
  };

  const setName = name => setData({ ...data, name });

  const setBio = bio => setData({ ...data, bio });

  const imageRef = createRef();

  const updateImageDataUrl = async ([file]) => {
    const uploadResult = await handleFileUpload(file, minimumBioImageDimensions);
    if (imageRef.current && uploadResult) {
      imageRef.current.src = uploadResult;
      setData({ ...data, imageUrl: uploadResult });
    }
  };

  const notifyFileRejected = rejectedFiles =>
    toast.error(`File(s) are the wrong type: "${rejectedFiles.map(file => file.name).join('", "')}"`);

  return (
    <>
      <Frame onClick={toggleZoom} style={{ display: isZoomed ? 'block' : 'none' }} />
      <BioBox
        onClick={toggleZoom}
        animate={animate}
        transition={{ type: 'tween', ease: 'easeOut' }}
        isZoomed={isZoomed}
      >
        {busy && <ProgressOverlay iconSize="5x" />}
        <BioBoxInner>
          <BioBoxIcon isZoomed={isZoomed} style={{ display: editing ? 'none' : 'block' }} />
          <EditableImage
            editing={editing}
            minimumResolution={minimumBioImageDimensions}
            onFileAccepted={updateImageDataUrl}
            onFileRejected={notifyFileRejected}
          >
            <BioImageContainer>
              <BioImage
                src={normaliseResourceUri(data.imageUrl, region, 'bio', index)}
                alt={`Portrait of ${data.name}`}
                isZoomed={isZoomed}
                ref={imageRef}
              />
            </BioImageContainer>
          </EditableImage>
          {isAuthenticated() && isZoomed && (
            <EditToggleContainer editing={editing}>
              <EditToggle editing={editing} edit={edit} save={save} cancel={cancel} />
            </EditToggleContainer>
          )}
          <TextContainer>
            <h4>
              <EditableText name="Name" text={data.name} setText={setName} editing={editing} />
            </h4>
            <Text isZoomed={isZoomed}>
              <EditableText name="Biography" text={data.bio} setText={setBio} editing={editing} />
            </Text>
          </TextContainer>
        </BioBoxInner>
      </BioBox>
    </>
  );
};
