import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { stopEventAnd } from '../../util';
import { isEditingEnabled } from '../../services/flags';

const Button = styled.button`
  display: inline-block;
  background: transparent;
  border: none;
  padding: 2px 4px;
  color: ${({ theme }) => theme.lightGrey};
  cursor: pointer;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 0.2rem;
`;

export const EditToggle = ({ editing, editText, edit, saveText, save, cancelText, cancel }) => {
  if (!isEditingEnabled()) {
    return null;
  }

  return editing ? (
    <>
      <Button type="button" onClick={stopEventAnd(cancel)}>
        <Icon icon="times-circle" size="sm" />
        {cancelText || 'Cancel'}
      </Button>
      <Button type="button" onClick={stopEventAnd(save)}>
        <Icon icon="upload" size="sm" />
        {saveText || 'Publish'}
      </Button>
    </>
  ) : (
    <Button type="button" onClick={stopEventAnd(edit)}>
      <Icon icon="edit" size="sm" />
      {editText || 'Edit'}
    </Button>
  );
};
