import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spin } from '../animation/Spin';

const Container = styled(motion.div)`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: ${({ theme }) => theme.secondaryWhite};
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 10;
`;

const Text = styled.div`
  display: ${({ layout }) => (layout === 'horizontal' ? 'inline-block' : 'block')};
  margin-left: ${({ layout }) => (layout === 'horizontal' ? '0.5rem' : '0')};
`;

export const ProgressOverlay = ({ iconSize, layout, text }) => (
  <Container>
    <div>
      <Spin>
        <FontAwesomeIcon icon="spinner" size={iconSize || '1x'} />
      </Spin>
      <Text layout={layout || 'vertical'}>{text || 'Saving...'}</Text>
    </div>
  </Container>
);
