export const timePerImageMs = 4000;
export const crossFadeTimeMs = 1000;

export const restartIntervalDurationMs = 30 * 1000;
export const restartTimeoutMs = 5 * 60 * 1000;

export const draggerWidth = 80;
export const circleWidth = 15;

export const triggerDistanceRatio = 0.27;
export const lateFadeTriggerDistanceRatio = 0.3375;

// Due to compatibility with Edge reasons SVGs can't use rem and em is broken, so I'm using breaking responsiveness :(
export const rootElementSize = 16;

export const purposeVideoElementId = 'video_purpose';
